/* Estilos da página Contato */

.contact {
    padding: 20px;
    text-align: center;
    background-color: #f9f9f9; /* Cor de fundo clara */
  }
  
  .contact-info {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: white; /* Fundo branco para destacar o conteúdo */
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .contact-info h2 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 20px;
  }
  
  .contact-info p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 15px;
  }
  
  .contact-info a {
    color: #007bff; /* Cor azul para links */
    text-decoration: none;
    font-weight: bold;
  }
  
  .contact-info a:hover {
    text-decoration: underline;
  }
  
  .contact-details {
    margin-top: 20px;
  }
  
  .contact-details strong {
    display: block;
    margin-bottom: 5px;
    font-size: 1.2rem;
    color: #333;
  }
  