/* Reset básico */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* Estilo global */
body {
  font-family: Arial, sans-serif;
  background-color: #f4f4f9;
  color: #333;
  line-height: 1.6;
  min-height: 100vh; /* Garante que o body ocupe 100% da altura da viewport */
  display: flex;
  flex-direction: column;
  width: 100vw;
}

/* App como um contêiner flexbox */
.App {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100vw;
}

/* Header fixo no topo com layout horizontal */
header {
  background-color: rgba(100, 100, 100, 0.8); /* Cinza translúcido */
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between; /* Distribui espaço entre o logo e o menu */
  align-items: center; /* Alinha verticalmente no centro */
  height: 5vh;
  max-width: 100vw;
}

header h1 {
  font-size: 2rem;
  margin: 0;
}

/* Menu de navegação horizontal */
nav {
  display: flex;
  gap: 20px; /* Espaçamento entre os links */
}

nav a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 10px;
}

nav a:hover {
  text-decoration: underline;
}

main {
  flex-grow: 1; /* Faz o main ocupar o espaço restante */
  padding: 20px;
  text-align: center;
  height: 90vh;
  max-width: 100vw;
  overflow: auto; /* Permite rolar se o conteúdo for maior que o container */
}

/* Estiliza a barra de rolagem para WebKit browsers */
main::-webkit-scrollbar {
  width: 0; /* Esconde a barra de rolagem vertical */
  height: 0; /* Esconde a barra de rolagem horizontal */
}

/* Adiciona uma classe para outros navegadores, se necessário */
main {
  -ms-overflow-style: none; /* Para Internet Explorer 10+ */
  scrollbar-width: none; /* Para Firefox */
}

h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

p, ul {
  font-size: 1.1rem;
  line-height: 1.6;
}

ul {
  list-style-type: none;
  padding-left: 0;
}

ul li {
  margin: 10px 0;
}

/* Rodapé sempre no final da página */
footer {
  background-color: rgba(100, 100, 100, 0.8); /* Cinza translúcido */
  color: white;
  text-align: center;
  padding: 10px 0;
  position: relative;
  bottom: 0;
  height: 5vh;
  max-width: 100vw;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  header h1 {
    font-size: 2rem;
    display: none;
  }

  nav {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  nav a {
    margin: 10px 0;
    font-size: 1rem;
  }

  main {
    padding: 10px;
  }

  h2 {
    font-size: 1.8rem;
  }

  p, ul {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  header h1 {
    font-size: 1.8rem;
  }

  nav a {
    font-size: 0.9rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p, ul {
    font-size: 0.9rem;
  }
}
