/* Estilos da página Home */

.home {
    background-image: url('https://as1.ftcdn.net/v2/jpg/08/23/90/66/1000_F_823906601_eoAlz53NXSbfbKds0A4aQlL8GES7HIow.jpg'); /* Substitua pelo link de sua imagem */
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    height: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: white;
    text-align: center;
    font-family: 'Montserrat', sans-serif; /* Usando uma tipografia marcante */
  }
  
  /* Overlay para adicionar uma camada escura sobre a imagem */
  .overlay {
    background-color: rgba(0, 0, 0, 0.6); /* Escurecendo a imagem de fundo */
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .hero {
    z-index: 1; /* Garantindo que o conteúdo apareça acima da overlay */
  }
  
  .hero h2 {
    font-size: 3.5rem;
    margin-bottom: 20px;
    text-transform: uppercase;
    letter-spacing: 3px;
    font-weight: 700;
    animation: fadeInDown 1s ease-in-out;
  }
  
  .hero p {
    font-size: 1.5rem;
    margin-bottom: 40px;
    max-width: 600px;
    line-height: 1.8;
    animation: fadeInUp 1s ease-in-out;
  }
  
  /* Animação suave para o conteúdo */
  @keyframes fadeInDown {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* Estilos responsivos */
  @media (max-width: 768px) {
    .hero h2 {
      font-size: 2.5rem;
    }
  
    .hero p {
      font-size: 1.2rem;
    }
  }
  
  @media (max-width: 480px) {
    .hero h2 {
      font-size: 2rem;
    }
  
    .hero p {
      font-size: 1rem;
    }
  }
  