/* Estilos da página Serviços */

  .services {
    padding: 20px;
    text-align: center;
  }
  
  .services-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    align-items: center;
  }
  
  .service-box {
    background-color: #f4f4f4;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s, box-shadow 0.3s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 250px;
    height: 250px; /* Tamanho fixo para todas as caixas */
  }
  
  .service-box:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .service-box h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  
  .service-box p {
    font-size: 1rem;
    flex-grow: 1;
  }
  
  .contact-box {
    background-color: #007bff; /* Cor de fundo azul para destaque */
    color: white;
  }
  
  .contact-box h3 {
    font-size: 1.5rem;
  }
  
  .contact-box p {
    margin-bottom: 20px;
  }
  
  .contact-link {
    color: white;
    text-decoration: none;
    font-weight: bold;
    border: 2px solid white;
    padding: 10px 20px;
    border-radius: 5px;
    transition: background-color 0.3s, color 0.3s;
  }
  
  .contact-link:hover {
    background-color: white;
    color: #007bff;
  }
  
    /* Estilos responsivos */
    @media (max-width: 768px) {
        .service-box {
            width: 100%;
        }
    }